.canvas__wrapper{
  position: fixed;
  z-index: 9;
  background-color: $primary-bg-color;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  & > canvas{
    width: 100%;
    height: 100%;
  }
}

.canvas__header{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  padding-top: 60px;
  pointer-events: none;

  @media screen and (max-width: 500px) {
    padding-top: 30px;
  }
}

.canvas__nav{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  
  & > .back-link{
    margin-bottom: 0;
    pointer-events: all;

    & > .label--gray {
      @media screen and (max-width: 500px) {
        display: none;
      }
    }
  }
}

.floor__nav{
  display: flex;
  flex-direction: row;
  align-items: center;
  pointer-events: all;

  & > p{
    cursor: pointer;
  }

  & > p.active {
    color: $primary-text-color;
  }

  & > p:not(:first-of-type) {
    margin-left: 40px;
  }
}

.canvas__badges{
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 500px) {
    left: 0;
    right: 0;
    top: 0;
    transform: none;
    max-width: auto;
    width: auto;
  }
}

.badge{
  margin: 5px 0;
  padding: 7px 15px;
  border-radius: 10px;
  color:  #999;
  background-color: rgba(28, 27, 25, 0.3);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  font-size: 12px;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 3px;
  stroke: #999;

  &.vip{
    color:  #ffed98;
    stroke: #ffed98;
  }

  &.small{
    padding: 5px 8px;
    margin: 0;
  }
}

.canvas__badges-list{
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  column-gap: 10px;
}